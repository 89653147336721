<template>
  <article
    :style="{
      backgroundImage: `url(${BgFurPng})`,
      backgroundColor: item.backgroundColor || undefined,
    }"
    class="relative flex flex-col items-center rounded-lg bg-black bg-cover bg-no-repeat py-2 text-center lg:py-0"
  >
    <img
      src="@valta/assets/images/fur-logo.png"
      alt=""
      class="w-[104px] object-cover lg:absolute lg:left-6 lg:top-3"
    />
    <div
      class="xs:w-[110px] absolute left-0 top-0 h-full w-[90px] overflow-hidden lg:left-[150px] lg:w-[115px]"
    >
      <img
        src="@valta/assets/images/fur-prod.png"
        alt=""
        class="xs:left-0 absolute -left-2 bottom-0 rounded-bl-lg object-cover lg:bottom-[-58px] lg:left-2 lg:rotate-[20deg] lg:rounded-bl-none"
      />
    </div>
    <div
      :style="{ color: item.titleColor || undefined }"
      class="px-13 xs:px-16 relative flex flex-col items-center space-y-1 py-3 text-white lg:max-w-3xl xl:max-w-4xl 2xl:max-w-6xl"
    >
      <div
        class="text-sm font-semibold leading-4 lg:text-base lg:leading-6"
        v-html="$sanitize(item.title)"
      />
      <p
        v-if="item.description"
        class="text-[10px] font-light sm:text-xs"
        v-html="$sanitize(item.description)"
      />
    </div>
    <Btn
      v-if="item.buttonHreff"
      :to="item.buttonHreff"
      :style="{
        backgroundColor: props.item.buttonColor || undefined,
        color: props.item.buttonTextColor || undefined,
      }"
      class="text-4 shadow-container mx-auto mt-3 inline-flex h-8 items-center rounded-lg bg-white px-4 font-medium text-black lg:absolute lg:right-[75px] lg:top-1/2 lg:m-0 lg:-translate-y-1/2"
    >
      {{ item.buttonText || 'Узнать подробности' }}
    </Btn>
    <Btn
      :style="{ color: props.item.buttonColor || undefined }"
      class="absolute right-2 top-2 text-white lg:right-9 lg:top-1/2 lg:-translate-y-1/2"
      @click="close"
    >
      <Icon name="close-round-fill" class="h-6 w-6" />
    </Btn>
  </article>
</template>

<script setup lang="ts">
import BgFurPng from '@valta/assets/images/bg-fur.png'
import type { CrossBanner } from '@/openapi_fetch'

const props = defineProps<{
  item: CrossBanner
}>()
const emit = defineEmits(['close'])

const { $sanitize } = useNuxtApp()

function close() {
  emit('close', props.item)
}
</script>
